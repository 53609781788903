import { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

import { ReactComponent as BlacksLogo } from "./images/logo.svg";
import "./styles/global.scss";

export default function Footer() {
  const [headerClass, setHeaderClass] = useState("");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setHeaderClass(window.scrollY > 400 ? "in-sticky" : "");
    });
  });

  return (
    <>
      <footer>
        <Container>
          <div className="footer-columns">
            <Row>
              <Col className="custom-set" xs={12} sm={5} md={5} xl={6} xxl={7}>
                <div className="logo-column">
                  <a className="footer-logo" href="#">
                    <BlacksLogo className="sitelogo" />
                  </a>
                  <p>
                    A global software design and <br />
                    development agency
                  </p>
                  <ul className="social-links-list">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/block-sw-solutions"
                        target="_blank"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaFacebookSquare />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagramSquare />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <FaDribbbleSquare />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col className="custom-set" xs={6} sm={3} md={2} xl={2} xxl={2}>
                <div className="link-column">
                  <h5>Site Links</h5>
                  <ul className="link-list">
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Why Us</a>
                    </li>
                    <li>
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col className="custom-set" xs={6} sm={3} md={3} xl={2} xxl={2}>
                <div className="link-column">
                  <h5>Other Links</h5>
                  <ul className="link-list">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Why Us</a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col className="custom-set" xs={5} sm={6} md={4} xl={4} xxl={3}>
                <div className="link-column">
                  <h5>Contact Info</h5>
                  <ul className="link-list">
                    {/* <li>
                      <a href="tel:123-456-7890">123-456-7890</a>
                    </li> */}
                    <li>
                      <a href="mailto:office@blockswsolutions.com">
                        office@blockswsolutions.com
                      </a>
                    </li>
                    <li>
                      <p>
                        Irvine, USA <br />
                        Toronto, Canada <br />
                        Plaški, Croatia <br />
                        Lahore, Pakistan
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="sub-footer">
            <p>© 2022 Block Software Solutions</p>
            <p>All Rights Reserved.</p>
          </div>
        </Container>
      </footer>
      <ScrollToTop id="back-to-top" smooth color="#fff" />
    </>
  );
}
