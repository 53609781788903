import { ReactComponent as BlacksLogo } from "./images/logo.svg";
import Lottie from "react-lottie";
import * as bannerAnimation from "./lotties/banner-animation.json";
import * as aboutUS from "./lotties/about-us.json";
import * as WhyUs from "./lotties/why-us.json";
import * as WhyUs2 from "./lotties/why-us2.json";
import * as SmallPlant from "./lotties/small-plant.json";
import * as loadingPlant from "./lotties/loading-plant.json";
import * as CryptoWallet from "./lotties/crypto-wallet.json";
import * as fly from "./lotties/Fly.json";
import * as contact from "./lotties/contact.json";
import * as about2 from "./lotties/about-ani.json";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitter,
  FaLinkedin,
  FaDribbbleSquare,
  FaDraftingCompass,
  FaEye,
} from "react-icons/fa";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-scroll";
import Slider from "react-slick";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ScrollToTop from "react-scroll-to-top";
import "./styles/global.scss";
import { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const aboutRef = useRef(null);
  const serviceHolderRef = useRef(null);
  const whyRef = useRef(null);
  const search = useLocation().search;
  const target = new URLSearchParams(search).get("t");

  useEffect(() => {
    switch (target) {
      case "about":
        aboutRef.current.scrollIntoView();
        break;
      case "services":
        serviceHolderRef.current.scrollIntoView();
        break;
      case "why-us":
        whyRef.current.scrollIntoView();
        break;

      default:
        break;
    }
    
  }, []);

  const [headerClass, setHeaderClass] = useState("");
  const [email, setEmail] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <BsChevronRight />,
    prevArrow: <BsChevronLeft />,
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setHeaderClass(window.scrollY > 400 ? "in-sticky" : "");
    });
  });

  const getStartedClicked = (event) => {
    setIsLoading(true);
    event.preventDefault();

    // window.open("https://forms.gle/YmCDVapAXkFn5mNX8", "_blank");
    // window.open(
    //   "https://share.hsforms.com/1CqbHol8gSROWQ9s8RhlUxwctsk7",
    //   "_blank"
    // );

    axios
      .post(
        "https://api.hsforms.com/submissions/v3/integration/submit/21545431/3d18e1ac-e740-413e-887f-926f3f334480",
        {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: email,
            },
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: "I agree to allow BLOCK Software Solutions d.o.o. to store and process my personal data.",
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: "I agree to receive marketing communications from BLOCL Software Solutions d.o.o.",
                },
              ],
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        alert(
          "You information saved successfully. We will get back to you soon"
        );
        setEmail("");
        setIsLoading(false);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
        setIsLoading(false);
      });
  };

  return (
    <div className="wrapper">
      <Header showContactUsButton={true}></Header>
      <div className="banner">
        <div className="banner-caption">
          <h1 className="mb-2">
            <span>One</span> Stop Shop
          </h1>
          <h3 className="mb-4">We Build Innovation Software Solutions.</h3>
          <p>
            We’re a global software design and development agency, specializing
            in the development of complex platforms, mobile and web applications
            and blockchain solutions.
          </p>
        </div>
        <div className="banner-animation">
          <Lottie
            options={{ ...defaultOptions, animationData: bannerAnimation }}
          />
        </div>
        {/* <div className="loadingPlant-animation">
          <Lottie
            options={{ ...defaultOptions, animationData: loadingPlant }}
          />
        </div> */}
        {/* <div className="cryptoWallet-animation">
          <Lottie
            options={{ ...defaultOptions, animationData: CryptoWallet }}
          />
        </div> */}
        <div className="shape-left-right"></div>
      </div>
      <div className="banner-steps-section">
        <Container>
          <Row>
            <Col md={4} lg={4} xl={4}>
              <div className="content">
                <span>01. </span>
                <div className="detail">
                  <h3>We are Selective</h3>
                  <p>
                    We offer lower than market cost to our partners because we
                    share risks together. Our reward is the success of the
                    product and it's bright future.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4} xl={4}>
              <div className="content">
                <span>02. </span>
                <div className="detail">
                  <h3>Who we work with</h3>
                  <p>
                    We understand the unique needs and experiences of
                    entrepreneurs. We can easily relate to their challenges and
                    opportunities.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4} xl={4}>
              <div className="content">
                <span>03. </span>
                <div className="detail">
                  <h3>Your software partner</h3>
                  <p>
                    We are looking for projects with the “right fit”. We rely on
                    our partners sharing their experiences with others.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <main className="main">
        <div className="about-product">
          <Container>
            <Row className="flex-column-reverse flex-md-row align-items-center">
              <Col md={6} lg={6} xxl={7}>
                <div className="about-content">
                  <div className="content">
                    <h2>
                      Your <span>Partner</span> <br /> For Digital Product
                    </h2>
                    <p>
                      We have strong track record, global market experience and
                      unique <br />
                      business approach.We don’t have clients, we have partners.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} xxl={4}>
                <div className="contact-lotti-animation">
                  <Lottie
                    options={{ ...defaultOptions, animationData: about2 }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="serv-part-section">
          <Container>
            <Row>
              <Col md={4} lg={4} xl={4}>
                <div className="content">
                  <span className="icon">
                    <img
                      className="block-img"
                      src={require("./images/ab-img-1.png")}
                      alt=""
                    />
                  </span>
                  <h4>WHY PARTNER WITH US?</h4>
                  <p>
                    We are one stop shop for all digital product driven
                    businesses. we create strategies, idealize products, design
                    & develop,manage product evolution and lifecycle.
                  </p>
                </div>
              </Col>
              <Col md={4} lg={4} xl={4}>
                <div className="content">
                  <span className="icon">
                    <img
                      className="block-img"
                      src={require("./images/ab-img-2.png")}
                      alt=""
                    />
                  </span>
                  <h4>EXCECUTION - LEAVE IT TO US</h4>
                  <p>
                    we turn ideas into actionable requirements, design/build
                    UX/UI, software architecture and scalability, develop
                    software product and finally maintain that product through
                    its lifecycle.
                  </p>
                </div>
              </Col>
              <Col md={4} lg={4} xl={4}>
                <div className="content">
                  <span className="icon">
                    <img
                      className="block-img"
                      src={require("./images/ab-img-3.png")}
                      alt=""
                    />
                  </span>
                  <h4>OUR BENEFITS?</h4>
                  <p>
                    You do not need to be concerned with Product and Marketing
                    Strategy, Development Resources, or Product and Project
                    Management. We manage everything for you.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-us-holder" ref={aboutRef}>
          <Container>
            <Row>
              <Col md={6} xxl={5}>
                <div className="about-animation">
                  <Lottie
                    options={{ ...defaultOptions, animationData: aboutUS }}
                  />
                </div>
              </Col>
              <Col md={6} xxl={7}>
                <div className="about-content">
                  <div className="content">
                    <h5 className="secondary-heading">
                      <FaDraftingCompass />
                      ABOUT US
                    </h5>
                    <h2>HOW WE DO IT</h2>
                    <p>
                      We start with a comprehensive analysis of what you and
                      your business are looking to achieve. We listen to your
                      ideas, put together an easy to follow proposal and
                      determine if we can work together.
                    </p>
                    <Button
                      variant="primary bs-primary-button"
                      size="lg"
                      onClick={() => navigate("/contact")}
                    >
                      Let's get started!
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="meet-greet-section">
          <Container>
            <Row>
              <Col md={4} lg={4} xl={3}>
                <div className="content">
                  <h3>
                    <span>01. </span>Meet & Greet
                  </h3>
                  <p>
                    Making sure that any web or mobile applications you develop
                    can grow alongside your business is important. We discuss
                    the best ways to do this and whether working together makes
                    sense.
                  </p>
                </div>
              </Col>
              <Col className="hide-lg">
                <div className="fly-animation style1">
                  <Lottie options={{ ...defaultOptions, animationData: fly }} />
                </div>
              </Col>
              <Col md={4} lg={4} xl={3}>
                <div className="content">
                  <h3>
                    <span>02. </span>Deep Dive
                  </h3>
                  <p>
                    Once we’ve decided to work on a project, we ensure that we
                    know exactly what needs to be delivered. We go over every
                    detail with you to ensure that you are getting what you’re
                    paying for.
                  </p>
                </div>
              </Col>
              <Col className="hide-lg">
                <div className="fly-animation style2">
                  <Lottie options={{ ...defaultOptions, animationData: fly }} />
                </div>
              </Col>
              <Col md={4} lg={4} xl={3}>
                <div className="content">
                  <h3>
                    <span>03. </span>Updates
                  </h3>
                  <p>
                    We provide complete updates and ongoing testing to our
                    clients not only so they can see the progress, but make any
                    changes or alterations as we go.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mid-text">
          <h1>Bring us problems, We'll create solutions.</h1>
        </div>
        <div className="service-holder" ref={serviceHolderRef}>
          <Container>
            <div className="service-bg">
              <Row>
                <Col md={12} lg={4} xxl={5}>
                  <div className="service-content">
                    <div className="content">
                      <h5 className="secondary-heading mb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#fd8c25"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M352 32C378.5 32 400 53.49 400 80V84H518.4C528.8 62.69 
                            550.7 48 576 48C611.3 48 640 76.65 640 112C640 147.3 611.3 176 576 
                            176C550.7 176 528.8 161.3 518.4 140H451.5C510.4 179.6 550.4 244.1 
                            555.5 320H560C586.5 320 608 341.5 608 368V432C608 458.5 586.5 480 
                            560 480H496C469.5 480 448 458.5 448 432V368C448 341.5 469.5 320 496 
                            320H499.3C493.4 253 450.8 196.6 391.8 170.9C383.1 183.6 368.6 192 352 
                            192H288C271.4 192 256.9 183.6 248.2 170.9C189.2 196.6 146.6 253 140.7 
                            320H144C170.5 320 192 341.5 192 368V432C192 458.5 170.5 480 144 
                            480H80C53.49 480 32 458.5 32 432V368C32 341.5 53.49 320 80 320H84.53C89.56 
                            244.1 129.6 179.6 188.5 140H121.6C111.2 161.3 89.3 176 64 176C28.65 176 
                            0 147.3 0 112C0 76.65 28.65 48 64 48C89.3 48 111.2 62.69 121.6 84H240V80C240 
                            53.49 261.5 32 288 32H352zM296 136H344V88H296V136zM88 376V424H136V376H88zM552 
                            424V376H504V424H552z"
                          />
                        </svg>
                        OUR SERVICES
                      </h5>
                      <h2>
                        Ready To Help You <br /> In Your Projects!
                      </h2>
                      <div className="small-plant-animation">
                        <Lottie
                          options={{
                            ...defaultOptions,
                            animationData: SmallPlant,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={8} xxl={7}>
                  <Row>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="42.198"
                            height="39.754"
                            viewBox="0 0 42.198 39.754"
                          >
                            <g
                              id="_-Product-Icons"
                              data-name="🔍-Product-Icons"
                              transform="translate(-2 -2)"
                            >
                              <g
                                id="ic_fluent_developer_board_24_regular"
                                transform="translate(2 2)"
                              >
                                <path
                                  id="_Color"
                                  data-name="🎨Color"
                                  d="M29.956,2a1.555,1.555,0,0,1,1.568,1.288l.014.2V8.112a7.7,7.7,0,0,1,6.17,5.814h4.906a1.488,1.488,0,0,1,.215,2.968l-.215.014H37.868v3.479h4.747a1.555,1.555,0,0,1,1.568,1.288l.014.2a1.524,1.524,0,0,1-1.368,1.477l-.215.014H37.868v3.479h4.747a1.555,1.555,0,0,1,1.568,1.288l.014.2a1.524,1.524,0,0,1-1.368,1.477l-.215.014-4.906,0a7.705,7.705,0,0,1-6.17,5.812v4.622a1.539,1.539,0,0,1-1.582,1.491,1.555,1.555,0,0,1-1.568-1.288l-.014-.2V35.789H24.679l0,4.474a1.524,1.524,0,0,1-1.368,1.477l-.215.014a1.555,1.555,0,0,1-1.568-1.288l-.014-.2,0-4.474h-3.69v4.474a1.524,1.524,0,0,1-1.368,1.477l-.215.014a1.555,1.555,0,0,1-1.568-1.288l-.014-.2V35.642a7.705,7.705,0,0,1-6.172-5.814H3.582a1.488,1.488,0,0,1-.215-2.968l.215-.014,4.747,0V23.366l-4.747,0A1.555,1.555,0,0,1,2.014,22.08L2,21.877A1.524,1.524,0,0,1,3.368,20.4l.215-.014,4.747,0V16.906l-4.747,0a1.555,1.555,0,0,1-1.568-1.288L2,15.417A1.524,1.524,0,0,1,3.368,13.94l.215-.014,4.906,0A7.705,7.705,0,0,1,14.66,8.112V3.491A1.539,1.539,0,0,1,16.242,2,1.555,1.555,0,0,1,17.81,3.288l.014.2v4.47h3.69l0-4.47a1.524,1.524,0,0,1,1.368-1.477L23.1,2a1.555,1.555,0,0,1,1.568,1.288l.014.2,0,4.47h3.694V3.491a1.509,1.509,0,0,1,1.162-1.438l.206-.04Zm0,8.945H16.242a4.616,4.616,0,0,0-4.747,4.472v12.92a4.616,4.616,0,0,0,4.747,4.472H29.956A4.616,4.616,0,0,0,34.7,28.337V15.417A4.616,4.616,0,0,0,29.956,10.945Zm-6.847,4.979a6.155,6.155,0,0,1,6.33,5.963,6.155,6.155,0,0,1-6.33,5.963,6.155,6.155,0,0,1-6.33-5.963A6.155,6.155,0,0,1,23.109,15.924Zm0,2.982a2.987,2.987,0,1,0,3.165,2.982A3.077,3.077,0,0,0,23.109,18.905Z"
                                  transform="translate(-2 -2)"
                                  fill="#fd8c25"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <h4>Blockchain Development</h4>
                        <p>dApps, Smart Contracts, Tokens.</p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="49.355"
                            height="42.223"
                            viewBox="0 0 49.355 42.223"
                          >
                            <g
                              id="_6729288061594722075"
                              data-name="6729288061594722075"
                              transform="translate(-1.5 -3.5)"
                            >
                              <line
                                id="Line_7"
                                data-name="Line 7"
                                x2="45.926"
                                transform="translate(3.429 15.333)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <g
                                id="Group_12551"
                                data-name="Group 12551"
                                transform="translate(8.349 8.566)"
                              >
                                <path
                                  id="Path_24164"
                                  data-name="Path 24164"
                                  d="M7.783,10.566a1.619,1.619,0,0,1-1.248-.535A1.619,1.619,0,0,1,6,8.783a1.619,1.619,0,0,1,.535-1.248l.178-.178c.178,0,.178-.178.357-.178C7.248,7,7.248,7,7.426,7h.713c.178,0,.178,0,.357.178.178,0,.178.178.357.178l.178.178c.178.178.357.357.357.535a1.072,1.072,0,0,1,.178.713,1.072,1.072,0,0,1-.178.713c0,.178-.178.357-.357.535A1.619,1.619,0,0,1,7.783,10.566Z"
                                  transform="translate(-6 -7)"
                                  fill="#fb8c35"
                                />
                              </g>
                              <g
                                id="Group_12552"
                                data-name="Group 12552"
                                transform="translate(13.697 8.559)"
                              >
                                <path
                                  id="Path_24165"
                                  data-name="Path 24165"
                                  d="M10.783,10.569a1.619,1.619,0,0,1-1.248-.535A1.619,1.619,0,0,1,9,8.786a1.072,1.072,0,0,1,.178-.713c.178-.178.178-.357.357-.535s.357-.357.535-.357a1.627,1.627,0,0,1,1.961.357c.178.178.357.357.357.535a1.072,1.072,0,0,1,.178.713,1.619,1.619,0,0,1-.535,1.248A1.619,1.619,0,0,1,10.783,10.569Z"
                                  transform="translate(-9 -6.996)"
                                  fill="#fb8c35"
                                />
                              </g>
                              <g
                                id="Group_12553"
                                data-name="Group 12553"
                                transform="translate(19.046 8.566)"
                              >
                                <path
                                  id="Path_24166"
                                  data-name="Path 24166"
                                  d="M13.783,10.566a1.072,1.072,0,0,1-.713-.178c-.178-.178-.357-.178-.535-.357s-.357-.357-.357-.535A1.072,1.072,0,0,1,12,8.783a1.072,1.072,0,0,1,.178-.713c.178-.178.178-.357.357-.535a1.723,1.723,0,0,1,2.5,0c.178.178.357.357.357.535a1.072,1.072,0,0,1,.178.713,1.072,1.072,0,0,1-.178.713c-.178.178-.178.357-.357.535A1.619,1.619,0,0,1,13.783,10.566Z"
                                  transform="translate(-12 -7)"
                                  fill="#fb8c35"
                                />
                              </g>
                              <path
                                id="Path_24167"
                                data-name="Path 24167"
                                d="M45.789,5H6.566A3.576,3.576,0,0,0,3,8.566V40.657a3.576,3.576,0,0,0,3.566,3.566H45.789a3.576,3.576,0,0,0,3.566-3.566V8.566A3.576,3.576,0,0,0,45.789,5Z"
                                transform="translate(0)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_24168"
                                data-name="Path 24168"
                                d="M13.349,16,8,21.349,13.349,26.7"
                                transform="translate(3.914 8.612)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_24169"
                                data-name="Path 24169"
                                d="M21,16l5.349,5.349L21,26.7"
                                transform="translate(14.092 8.612)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <line
                                id="Line_8"
                                data-name="Line 8"
                                x1="6.889"
                                y2="13.778"
                                transform="translate(22.947 23.37)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                            </g>
                          </svg>
                        </div>
                        <h4>WEB App Development</h4>
                        <p>
                          Custom Web Apps for your business. Cloud and On-site..
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="48"
                            viewBox="0 0 28 48"
                          >
                            <g
                              id="_7442810861529659196"
                              data-name="7442810861529659196"
                              transform="translate(-4 1.484)"
                            >
                              <line
                                id="Line_6"
                                data-name="Line 6"
                                x1="25"
                                transform="translate(5.5 37.016)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_24163"
                                data-name="Path 24163"
                                d="M12.7,20.75a1.451,1.451,0,1,0,1.028.425A1.453,1.453,0,0,0,12.7,20.75Z"
                                transform="translate(5.387 18.939)"
                                fill="#fd8c25"
                              />
                              <rect
                                id="Rectangle_7761"
                                data-name="Rectangle 7761"
                                width="25"
                                height="45"
                                rx="2"
                                transform="translate(5.5 0.016)"
                                fill="none"
                                stroke="#fb8c35"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                            </g>
                          </svg>
                        </div>
                        <h4>Mobile App Development</h4>
                        <p>
                          Whether Android or iOS, we create mobile apps that you
                          can be proud of.
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                          >
                            <g
                              id="phone_desktop_20_regular"
                              transform="translate(-2 -2)"
                            >
                              <path
                                id="Path_363"
                                data-name="Path 363"
                                d="M10.25,5.125H41.5A3.125,3.125,0,0,1,44.625,8.25V30.125A3.125,3.125,0,0,1,41.5,33.25H19.625v3.125H29v6.25H19.625V45.75H36.813a1.563,1.563,0,0,0,0-3.125H32.125v-6.25H41.5a6.25,6.25,0,0,0,6.25-6.25V8.25A6.25,6.25,0,0,0,41.5,2H10.25A6.25,6.25,0,0,0,4,8.25v9.375H7.125V8.25A3.125,3.125,0,0,1,10.25,5.125Z"
                                transform="translate(4.25)"
                                fill="#fd8c25"
                              />
                              <path
                                id="Path_364"
                                data-name="Path 364"
                                d="M5.563,15a1.563,1.563,0,0,0,0,3.125H8.688a1.563,1.563,0,0,0,0-3.125Z"
                                transform="translate(4.25 27.625)"
                                fill="#fd8c25"
                              />
                              <path
                                id="Path_365"
                                data-name="Path 365"
                                d="M2,12.688A4.687,4.687,0,0,1,6.688,8h9.375a4.687,4.687,0,0,1,4.688,4.688V34.563a4.688,4.688,0,0,1-4.687,4.688H6.688A4.688,4.688,0,0,1,2,34.563Zm4.688-1.562a1.563,1.563,0,0,0-1.562,1.563V34.563a1.563,1.563,0,0,0,1.563,1.563h9.375a1.563,1.563,0,0,0,1.563-1.562V12.688a1.563,1.563,0,0,0-1.562-1.562Z"
                                transform="translate(0 12.75)"
                                fill="#fd8c25"
                              />
                            </g>
                          </svg>
                        </div>
                        <h4>
                          UI/UX <br /> Design
                        </h4>
                        <p>
                          UX/UI that drives both business and end-user
                          experience..
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            id="_-Product-Icons"
                            data-name="🔍-Product-Icons"
                            xmlns="http://www.w3.org/2000/svg"
                            width="38.398"
                            height="48"
                            viewBox="0 0 38.398 48"
                          >
                            <g id="ic_fluent_bot_24_regular">
                              <path
                                id="_-Color"
                                data-name="🎨-Color"
                                d="M37,30.8a5.4,5.4,0,0,1,5.4,5.4V38.37A9,9,0,0,1,39.264,45.2C35.507,48.424,30.13,50,23.194,50S10.887,48.425,7.136,45.2A9,9,0,0,1,4,38.375V36.2a5.4,5.4,0,0,1,5.4-5.4Zm0,3.6H9.4a1.8,1.8,0,0,0-1.8,1.8v2.177a5.4,5.4,0,0,0,1.88,4.095c3.019,2.6,7.57,3.93,13.711,3.93s10.7-1.335,13.724-3.933a5.4,5.4,0,0,0,1.882-4.1V36.2A1.8,1.8,0,0,0,37,34.4ZM22.951,2.017,23.2,2a1.8,1.8,0,0,1,1.784,1.556L25,3.8l0,1.8h8.4a5.4,5.4,0,0,1,5.4,5.4V21.81a5.4,5.4,0,0,1-5.4,5.4h-20.4a5.4,5.4,0,0,1-5.4-5.4V11a5.4,5.4,0,0,1,5.4-5.4h8.4l0-1.8a1.8,1.8,0,0,1,1.556-1.784L23.2,2ZM33.394,9.2h-20.4a1.8,1.8,0,0,0-1.8,1.8V21.81a1.8,1.8,0,0,0,1.8,1.8h20.4a1.8,1.8,0,0,0,1.8-1.8V11A1.8,1.8,0,0,0,33.394,9.2Zm-15.6,3.6a3,3,0,1,1-3,3A3,3,0,0,1,17.793,12.8Zm10.782,0a3,3,0,1,1-3,3A3,3,0,0,1,28.575,12.8Z"
                                transform="translate(-4.004 -2)"
                                fill="#fd8c25"
                              />
                            </g>
                          </svg>
                        </div>
                        <h4>
                          Quality <br /> Assurance
                        </h4>
                        <p>
                          We make sure that what we build works right. Dedicated
                          QA resources with “Test to fail” mission.
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <div className="service-card">
                        <div className="service-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="_-Product-Icons"
                            data-name="🔍-Product-Icons"
                            width="38.4"
                            height="48"
                            viewBox="0 0 38.4 48"
                          >
                            <g
                              id="ic_fluent_lock_24_regular"
                              transform="translate(0 0)"
                            >
                              <path
                                id="_-Color"
                                data-name="🎨-Color"
                                d="M23.2,2a9.6,9.6,0,0,1,9.6,9.6v4.8H37a5.4,5.4,0,0,1,5.4,5.4V44.6A5.4,5.4,0,0,1,37,50H9.4A5.4,5.4,0,0,1,4,44.6V21.8a5.4,5.4,0,0,1,5.4-5.4h4.2V11.6A9.6,9.6,0,0,1,23.2,2ZM37,20H9.4a1.8,1.8,0,0,0-1.8,1.8V44.6a1.8,1.8,0,0,0,1.8,1.8H37a1.8,1.8,0,0,0,1.8-1.8V21.8A1.8,1.8,0,0,0,37,20ZM23.2,29.6a3.6,3.6,0,1,1-3.6,3.6A3.6,3.6,0,0,1,23.2,29.6Zm0-24a6,6,0,0,0-6,6v4.8h12V11.6A6,6,0,0,0,23.2,5.6Z"
                                transform="translate(-4 -2)"
                                fill="#fd8c25"
                              />
                            </g>
                          </svg>
                        </div>
                        <h4>
                          System <br /> Security
                        </h4>
                        <p>
                          We utilize the latest security tactics to ensure that
                          privacy isn’t compromised.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="why-block-holder" ref={whyRef}>
          <Container>
            <Row className="flex-column-reverse flex-md-row">
              <Col md={6} xxl={7}>
                <div className="block-content">
                  <div className="content">
                    <h5 className="secondary-heading">
                      <FaEye />
                      WHY BLOCK
                    </h5>
                    <h2>Launch With The Best Stack</h2>
                    <p>
                      Maintenance-free data pipelines with quick set-up and
                      straight-forward deployments that are <br /> powered by a
                      modern & scalable platform data pipelines with quick
                      set-up.
                    </p>
                    <ul className="block-bullets-detail">
                      <li>
                        <h4>UX driven</h4>
                        <span>UX driven approach</span>
                      </li>
                      <li>
                        <h4>Move at your pace</h4>
                        <span>Get to market in as little as 3 months</span>
                      </li>
                      <li>
                        <h4>Comprehensive quote</h4>
                        <span>No hidden costs</span>
                      </li>
                      <li>
                        <h4>Process transparency</h4>
                        <span>No surprises</span>
                      </li>
                      <li>
                        <h4>Tech stack</h4>
                        <span>
                          Best tech stack based on your business needs
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={6} xxl={5}>
                <div className="block-animation">
                  <img
                    className="block-img"
                    src={require("./images/whyus-bg.png")}
                    alt="User profile image"
                  />
                  <Lottie
                    options={{ ...defaultOptions, animationData: WhyUs }}
                  />
                  <Lottie
                    options={{ ...defaultOptions, animationData: WhyUs2 }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="testimonial-holder">
          <div className="shape-right-left"></div>
          <Container>
            <Slider className="testimonial-slider-holder" {...settings}>
              <div className="testimonial-slider">
                <div className="testimonial-text">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.114"
                      height="19.534"
                      viewBox="0 0 24.114 19.534"
                    >
                      <path
                        id="Qoute_Start"
                        data-name="Qoute Start"
                        d="M11.1,13.98l4.3,2.918A19.115,19.115,0,0,0,11.5,28v5.512H4.044v-4.7A18.594,18.594,0,0,1,6.01,20.626,19.626,19.626,0,0,1,11.1,13.98Zm12.766,0,4.3,2.918A19.115,19.115,0,0,0,24.267,28v5.512H16.81v-4.7a18.594,18.594,0,0,1,1.966-8.187A19.626,19.626,0,0,1,23.862,13.98Z"
                        transform="translate(-4.044 -13.98)"
                        fill="#fff"
                      />
                    </svg>
                    What I love about Block is the easy way we can collaborate
                    even if there is a lot of people involved in the process.”
                  </p>
                </div>
                <div className="user-profile">
                  <div className="profile-img">
                    <img
                      src={require("./images/user-profile-img.png")}
                      alt="User profile image"
                    />
                  </div>
                  <h5>
                    Guillaume Cabane <span>CTO @ BigSpring</span>
                  </h5>
                </div>
              </div>
              <div className="testimonial-slider">
                <div className="testimonial-text">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.114"
                      height="19.534"
                      viewBox="0 0 24.114 19.534"
                    >
                      <path
                        id="Qoute_Start"
                        data-name="Qoute Start"
                        d="M11.1,13.98l4.3,2.918A19.115,19.115,0,0,0,11.5,28v5.512H4.044v-4.7A18.594,18.594,0,0,1,6.01,20.626,19.626,19.626,0,0,1,11.1,13.98Zm12.766,0,4.3,2.918A19.115,19.115,0,0,0,24.267,28v5.512H16.81v-4.7a18.594,18.594,0,0,1,1.966-8.187A19.626,19.626,0,0,1,23.862,13.98Z"
                        transform="translate(-4.044 -13.98)"
                        fill="#fff"
                      />
                    </svg>
                    What I love about Block is the easy way we can collaborate
                    even if there is a lot of people involved in the process.”
                  </p>
                </div>
                <div className="user-profile">
                  <div className="profile-img">
                    <img
                      src={require("./images/user-profile-img.png")}
                      alt="User profile image"
                    />
                  </div>
                  <h5>
                    Guillaume Cabane <span>CTO @ BigSpring</span>
                  </h5>
                </div>
              </div>
            </Slider>
          </Container>
          <div className="shape-left-right"></div>
        </div> */}
        <div className="projects-and-clients">
          <Container>
            {/* <div className="successful-projects">
              <div className="main-head">
                <h2>
                  we completed 1200+ projects yearly
                  <br /> successfully & counting
                </h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its
                  <br /> layout. The point of using Lorem Ipsum is that it has a
                  more-or-less.
                </p>
              </div>
              <Row className="acounter">
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={3273} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Projects Complete</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={2560} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Active Projects</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={323} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Client Satisfied</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={54} />
                        ) : null}
                        <i className="plus-ac">+</i>
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Country Available</h4>
                </Col>
              </Row>
            </div>
            <div className="our-clients">
              <h2>Our Trusted Clients</h2>
              <div className="client-logos">
                <img
                  src={require("./images/clients-logos.png")}
                  alt="Clients Logos"
                />
              </div>
            </div> */}
            <div className="contact-us">
              <div className="small-plant-animation left">
                <Lottie
                  options={{ ...defaultOptions, animationData: SmallPlant }}
                />
              </div>
              <Row className="align-items-center justify-content-md-center">
                <Col sm={12} md={6} lg={6}>
                  <div className="content">
                    <h2>Subscribe Us!</h2>
                    <p>
                      Get in touch today to discover what we can do for you.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Form className="search-bar" onSubmit={getStartedClicked}>
                    <Form.Group controlId="">
                      <Form.Control
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Your email"
                      />
                    </Form.Group>
                    <Button
                      variant="primary bs-primary-button"
                      size="sm"
                      type="submit"
                    >
                      Send
                    </Button>
                  </Form>
                </Col>
              </Row>
              <div className="small-plant-animation right">
                <Lottie
                  options={{ ...defaultOptions, animationData: SmallPlant }}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
      <Footer></Footer>
      <div className={`page-loader ${isLoading ? "true" : ""}`}>
        <div class="loader">
          <div class="circle green"></div>
          <div class="circle orange"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
